import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Product from '../components/product'
const ProductPage = () => (

  <Layout>
    <SEO title="Electrically Height Adjustable Table" />
<Product/>

  </Layout>
)

export default ProductPage