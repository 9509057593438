import React from 'react'
import styled from 'styled-components'
import {useStaticQuery,  graphql} from 'gatsby'
import Img from 'gatsby-image'
import Btn from './btn'
import OBtn from './orderbtn'

export default function BuyNow({tabled, imge, upimg, uptxt, oldprice,  price, imgeStell, text}) {


const colordata = useStaticQuery(graphql`

query  {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
        sourceInstanceName: {eq: "tablelogo"}}, sort: {fields: size}) 
      {
        edges {
          node {
            childImageSharp {
              fluid {
              originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }


`)


const color_Data = colordata.allFile.edges

// console.log(color_Data.node.childImageSharp.fluid.originalName);

    return (
        <BuyNowWrap className="container-fluid bg-light px-0 py-sm-5 py-3" >

<div className="row bg-light no-gutter">
<div className="col-sm-5">
    <img src={imge} alt="stell" width="100%"/>
    <div className="btnContent">
            <Btn price={price} oldprice={oldprice}/>
            <OBtn/>

            <p style={{fontSize:"1.1rem"}}  className="text-center mb-3 mb-sm-1 text-black" >Inclusive of all charges –includes delivery (in Bangalore), Installation and all applicable taxes
Mega size of 2.5 feet X 5 feet Tabletop.For custom sizes or outside Bangalore delivery, please drop us a mail to interiors@designseries.co.in</p>
</div>

</div>
<div className="col-md-7">

    <div className="row">

        <div className="col-md-6" style={{position:"relative"}}>

            <img src={imgeStell} alt="steel" width="100%"/>

                    <img src={upimg} className="d-block mx-auto mt-2" alt="control" width="80%"/>

        <h3 className="text-dark text-center ">{uptxt}
        </h3>        
      

        </div>
      
        <div className="col-md-6">
<h4 className="text-danger text-center ">{text}</h4>

        {

tabled.map((item, i)=>{

     return <div className="tabletext py-1 px-4" key={i}>

         <div id={item.id} className="text">{item.text}</div>
         <div id={item.id} className="unit">{item.unit}</div>

      </div>

 })


}

<p className="description mt-2">Legs, feet and top supports made of powder coated steel.
The crossbar is made of galvanized steel. Soft star & stop function.
Spindle lifting system and low energy consumption.</p>
        </div>

    </div>
    <div className="row mt-2 pt-2">

<div className="col-md-6">
<h3 className="text-danger text-center ">Colors Available For The Tabletop
        </h3>
<div className="row">
    {

color_Data.map((item, i)=>{

return <div className="col-md-6 p-2 col-6 colortable" key={i}>

<div clasName="p-0 m-0 " style={{ overflow:"hidden"}}>
    <Img fluid={item.node.childImageSharp.fluid} alt="color" style={{width:"100%", height:"150px"}}/>

</div>
<p className="pt-2 text-center text-dark" style={{fontWeight:"bold"}}>{item.node.childImageSharp.fluid.originalName.replace(".png", "$'")}</p>

</div>

})
    
}
    
</div>




</div>
<div className="col-sm-6">

<h3 className="text-danger text-center pt-4">Colors Available For The Metal Frame
        </h3>

        <div className="color">

            <div style={{backgroundColor:"black"}}></div>
            <div style={{backgroundColor:"white"}}></div>

        </div>

</div>

</div>

    
</div>

</div>
     

        </BuyNowWrap>
    )
}

const BuyNowWrap = styled.div`

background-color:#111111;


.tabletext{

    display :flex;
    align-items:center;
    justify-content:space-between;
    border:1px solid grey;
 
    border-bottom:0.1px solid grey;
    font-size:1rem;
    font-family: 'Montserrat', sans-serif;

   
}

.colors{

    margin-top:4rem;
}


.color{

    display:flex;
     align-items:center;
    justify-content:center;
   
 

    div{

        width:80px;
        height:80px;
        border:0.5px solid grey;
        margin:1rem;
        border-radius:5px;
    }

}


#first{

    color:#d03131  !important;
    text-transform:uppercase;
    font-weight:800;
}

`