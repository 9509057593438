import React from 'react'
import styled from 'styled-components'
import {useStaticQuery,  graphql} from 'gatsby'
import Img from 'gatsby-image'
export default function OtherProduct() {

    const otherProductData = useStaticQuery(graphql`

    query  {
          allFile(filter: {
            extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
            sourceInstanceName: {eq: "otherimages"}}, sort: {fields: size}) 
          {
            edges {
              node {
                childImageSharp {
                  fluid {
                  originalName
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
    
    
    `)
    
    
    const OtherProduct = otherProductData.allFile.edges


    return (
        <OtherProductWrap className="container-fluid bg-light">
<h1 className="text-danger text-left ">Other Models
        </h1>
<div className="row">
    {

OtherProduct.map((item, i)=>{

return <div key={i} className="col-sm-4 my-3">

<Img fluid={item.node.childImageSharp.fluid} alt="color" style={{width:"100%"}}/>
<h3 className="mb-4 text-center text-dark" style={{fontWeight:"bold", textTransform:"uppercase"}}>{item.node.childImageSharp.fluid.originalName.replace(".png", "$'")}</h3>

</div>

})
    }
    
</div>
            
        </OtherProductWrap>
    )
}


const OtherProductWrap = styled.div`



`