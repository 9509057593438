import React from 'react'
import Header from './header'
import BuyNow from './buynow'
import steel1 from '../../images/shop/steel.jpg'
import steel2 from '../../images/shop/steel2.png'
import steel3 from '../../images/shop/steel3.png'

import upimg1 from '../../images/AustenTable/updowntouchhead.png'
import upimg4 from '../../images/AustenTable/ipwith4memory.png'


import simg1 from '../../images/shop/simg2.png'
import simg2 from '../../images/shop/simg1.png'
import simg3 from '../../images/shop/simg3.png'

import OtherProduct from './otherProduct'

import mb1 from '../../images/shop/mb1.jpg'

import styled from 'styled-components'
export default function Product() {



    const tabledata = [
        {

            text :"steelforce",
            unit:"Pro 300 SLS",
            id:"first"
        },

        {
        text:"Height(mm)",
        unit:"min. 690/max. 1180"
    },
    {
        text:"Stroke (mm)",
        unit:"490"
    },
    {
        text:"Speed ( mm/s)",
        unit:"39"
    },
    {
        text:"Width (mm)",
        unit:"min 1000/max.1500"
    },
    {
        text:"Length of feet(mm)",
        unit:"750"
    },
    {
        text:"Column size ( mm)",
        unit:"60 x 60"
    },
    {
        text:"Dynamic Capacity(kg)",
        unit:"100"
    },
    {
        text:"Noise dB(A) ",
        unit:"<55"
    },
    {
        text:"Power",
        unit:"V 220-240"
    },
    {
        text:"Plug type",
        unit:"Replaceable"
    },
    {
        text:"Weight (kg)",
        unit:"26.5"
    }
    
    ]


    const tabledata2 = [

        {

            text :"Steelforce",
            unit:"Pro 370 SLS",
            id:"first"
        },
        {
        text:"Height(mm)",
        unit:"min.690 /max 1180"
    },
    {
        text:"Stroke (mm)",
        unit:"490"
    },
    {
        text:"Speed ( mm/s)",
        unit:"39"
    },
    {
        text:"Width (mm)",
        unit:"min 1100/max.1700"
    },
    {
        text:"Depth of feet(mm)",
        unit:"750"
    },
    {
        text:"Column size ( mm)",
        unit:"70 x 70"
    },
    {
        text:"Dynamic Capacity(kg)",
        unit:"80"
    },
    {
        text:"Noise dB(A) ",
        unit:"<55"
    },
    {
        text:"Power",
        unit:"V 220-240"
    },
    {
        text:"Plug type",
        unit:"Replaceable"
    },
    {
        text:"Weight (kg)",
        unit:"30.5"
    }
    
    ]


    const tabledata3 = [
        {

            text :"Steelforce",
            unit:"Pro 470 SLS",
            id:"first"
        },
        {
        text:"Height(mm)",
        unit:"min.615 /max 1275"
    },
    {
        text:"Stroke (mm)",
        unit:"660"
    },
    {
        text:"Speed ( mm/s)",
        unit:"39"
    },
    {
        text:"Width (mm)",
        unit:"min 1100/max.1700"
    },
    {
        text:"Depth of feet(mm)",
        unit:"750"
    },
    {
        text:"Column size ( mm)",
        unit:"70 x 70"
    },
    {
        text:"Dynamic Capacity(kg)",
        unit:"80"
    },
    {
        text:"Noise dB(A) ",
        unit:"<55"
    },
    {
        text:"Power",
        unit:"V 220-240"
    },
    {
        text:"Plug type",
        unit:"Replaceable"
    },
    {
        text:"Weight (kg)",
        unit:"32"
    }
    
    ]

    return (
        <MainWrap className="container-fluid bg-light p-0">
<Header/>
<div className="container">
<BuyNow uptxt="Up Down Touch Hand Controller"
 upimg={upimg1} imge={simg1}     oldprice="₹35,425"

  text="Single step, sit-stand height electrically adjustable"
   imgeStell={steel1} price="₹27,250" tabled = {tabledata}/>


<BuyNow uptxt="Up Down Touch Hand Controller"
 upimg={upimg1} imge={simg2}     oldprice="₹40,900"

 text="Single step, sit-stand height electrically adjustable"
  imgeStell={steel2} price="₹31,462" tabled = {tabledata2}/>


<BuyNow
 upimg={upimg4}
  imge={simg3}
  uptxt="With 4 memory buttons TouchFxHand Controller"
   text="Dual step, sit-stand height electrically adjustable"
    imgeStell={steel3} price="₹37,187"
    oldprice="₹48,344"
     tabled = {tabledata3}/>


<OtherProduct/>

</div>




        </MainWrap>
    )
}

const MainWrap = styled.div`

.mb{

display:none;

}
.ds{

display:block;

}


@media(max-width:960px){

.mb{

display:block;

}
.ds{

display:none;

}


}
`