import React from 'react'
import styled from 'styled-components'

export default function Btn({price, oldprice}) {
    return (
    
        <BtnWrap>
      <div className="abtn">SALE PRICE <b className="text-success">30% OFF</b></div>

       <span style={{textDecoration:" line-through", opacity:0.5}} className="text-light">{oldprice}</span> /  {price}
        </BtnWrap>
    )
}

const BtnWrap = styled.div`


padding: 1.8rem 5rem 1.2rem 5rem;
position: relative;
background-color:#9E1140;
width:80%;
color:white;
border-radius:0.8rem;
box-shadow: 0 1px 20px 1px #565656;
font-size:1.6rem;
font-weight:bold;
margin:auto;
text-align:center;
transform:translateY(-2.5rem);
cursor: pointer;


.abtn{
    padding:0.6rem 1.7rem;
    position:absolute;
    color:#9E1140;
    width:260px;
    margin:auto;
    top:-11%;
    left:50%;
    text-align:center;
    font-weight:bold;
    transform:translate(-50%, -50%);
    background-color:white;
    border-radius:0.5rem;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.2);
    font-size:1.2rem;


}

@media(max-width:960px){
    padding:1.7rem 1rem 1rem 1rem;

    .abtn{

width:200px;
font-size:1rem;

    }
    


}
`