import React from 'react'
import styled from 'styled-components'
export default function Header() {
    return (
        <HeaderWrap className=" container-fluid py-sm-5 py-3">
        <div>
          <h2 style={{textTransform:"uppercase"}}>Electrically Height Adjustable Desk</h2>   
            </div>    
     

        </HeaderWrap>
    )
}

const HeaderWrap = styled.div`

background-color:#111111;
display:flex;
align-items:center;
justify-content:center;
height:200px;

h2{

    color:grey;
    font-size:3.5rem;

    text-align:center;
}
@media(max-width:960px){

h2{

    font-size:2rem;
}

}

`
